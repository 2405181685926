/**
 * This file contains all the routing and menu generation setup.
 * If you want to add another route + view , you have to do it in here.
 * `title`: title of item
 * `route`: route to assign
 * `url`: if you don't specify a local route, use a url to redirect to an external site
 * `component`: what to render
 * `needsAuth`: does the user need to have authentication
 * `menu`: show in top menu
 * `onlyShowIf`: only show if a certain key is true (looks in this.props.features and session.config)
 * `triggerAction`: also triggers an action in redux
 * @format
 */

import React from 'react';

import { lastWeek } from '@mollybet/frontend-common/dist/lib/time';
import Whitelabel from '@mollybet/frontend-common/dist/lib/Whitelabel';

const Login = React.lazy(() => import('./views/Login'));
const ResetPassword = React.lazy(() => import('./views/ResetPassword'));
const Register = React.lazy(() => import('./views/Register'));
const Trade = React.lazy(() => import('./views/Trade'));
const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'));
const EventView = React.lazy(() => import('./views/event-view/EventView'));
const ActivePositions = React.lazy(() => import('./views/ActivePositions'));
const HistoryOrders = React.lazy(() => import('./views/history/Orders'));
const HistoryBets = React.lazy(() => import('./views/history/Bets'));
const Accounting = React.lazy(() => import('./views/history/accounting/AccountingPage'));

const Settings = React.lazy(() => import('./views/Settings'));
const Terms = React.lazy(() => import('./views/Terms'));

const AgentHome = React.lazy(() => import('./views/agent/AgentHome'));
const AdminHome = React.lazy(() => import('./views/agent/AdminHome'));

const routes = {
  items: [
    {
      title: { id: 'nav.routes.login_reset', defaultMessage: 'Reset Password' },
      route: '/reset/:resetToken',
      component: ResetPassword,
      needsAuth: false,
      menu: false,
    },
    {
      title: { id: 'nav.routes.login_reset', defaultMessage: 'Reset Password' },
      route: '/reset/:resetToken',
      component: ResetPassword,
      needsAuth: false,
      menu: false,
    },
    {
      title: { id: 'nav.routes.login', defaultMessage: 'Login' },
      route: '/login',
      component: Login,
      needsAuth: false,
      menu: false,
      demo: true,
    },
    {
      title: { id: 'nav.routes.register', defaultMessage: 'Register' },
      route: '/registration/:registrationId?/:currentAction?',
      component: Register,
      needsAuth: false,
      menu: false,
    },
    {
      title: { id: 'nav.routes.trade', defaultMessage: 'Trade' },
      route: '/trade/:sport?/:eventId?/:action?/:betType?',
      url: '/trade',
      component: Trade,
      needsAuth: true,
      menu: true,
      demo: true,
    },
    {
      title: { id: 'nav.routes.eventView.eventView', defaultMessage: 'Events' },
      route: '/event/:selectedSport?/:competitionId?/:eventId?',
      url: '/event',
      component: EventView,
      needsAuth: true,
      menu: true,
      demo: true,
      onlyShowIf: '!narrow',
    },
    {
      title: { id: 'nav.routes.trade', defaultMessage: 'Trade' },
      route: '/beta',
      url: '/beta',
      component: Trade,
      needsAuth: true,
      menu: false,
    },
    {
      title: { id: 'nav.routes.history.statement', defaultMessage: 'My Orders' },
      route: '/history/orders',
      component: HistoryOrders,
      needsAuth: true,
      menu: true,
      hash: ['base', 'settings', 'history', 'orders', 'lastQuery'],
    },
    {
      title: { id: 'nav.routes.mobileMenu', defaultMessage: 'Dashboard' },
      route: '/dashboard',
      component: Dashboard,
      needsAuth: true,
      menu: false,
    },
    {
      title: { id: 'nav.routes.history.pl', defaultMessage: 'Analytics' },
      route: '/history/bets',
      component: HistoryBets,
      needsAuth: true,
      menu: false,
      hash: ['base', 'settings', 'history', 'bets', 'lastQuery'],
      hashFallback: `#q=f:bookie_date_day:ge:${lastWeek()['dateFrom']}/f:bookie_date_day:le:${
        lastWeek()['dateFrom']
      }`,
    },
    {
      title: { id: 'nav.routes.activePositions', defaultMessage: 'My Positions' },
      route: '/activePositions/:sport/:eventId', //variant for sport and event
      url: '/activePositions',
      component: ActivePositions,
      needsAuth: true,
      menu: false,
    },
    {
      title: { id: 'nav.routes.activePositions', defaultMessage: 'My Positions' },
      route: '/activePositions/:orderId', //variant for just orderId
      url: '/activePositions',
      component: ActivePositions,
      needsAuth: true,
      menu: false,
    },
    {
      title: { id: 'nav.routes.activePositions', defaultMessage: 'My Positions' },
      route: '/activePositions',
      url: '/activePositions',
      component: ActivePositions,
      needsAuth: true,
      menu: false,
      onlyShowIf: '!agent',
    },
    {
      title: { id: 'nav.routes.history.accounting.Accounting', defaultMessage: 'Accounting' },
      route: '/history/accounting/:category?/:username?/:userCcy?',
      component: Accounting,
      needsAuth: true,
      menu: false,
    },
    {
      title: { id: 'nav.routes.settings', defaultMessage: 'Settings' },
      route: '/settings/:category?',
      component: Settings,
      needsAuth: true,
      menu: false, //because we take care of it above the login button
    },
    {
      title: { id: 'nav.routes.agent', defaultMessage: 'Agent' },
      route: '/agent',
      component: AgentHome,
      onlyShowIf: 'agent',
      needsAuth: true,
      menu: false,
    },
    {
      title: { id: 'nav.routes.agent', defaultMessage: 'Admin' },
      route: '/admin',
      component: AdminHome,
      onlyShowIf: 'superuser || limitedAdmin',
      needsAuth: true,
      menu: false,
    },
    {
      title: { id: 'nav.routes.useMobile', defaultMessage: 'Use Mobile' },
      url: Whitelabel.mobilePlatformLink,
      triggerAction: 'useMobileInterface', //fake action, not implemented anywhere
      menu: true,
      onlyShowIf: 'narrow && mobilePlatformRedirect', //special
    },
    {
      title: { id: 'nav.routes.useAlpha', defaultMessage:  `Beta` },
      url: '/alpha/trade',
      menu: true,
      onlyShowIf: 'narrow',
    },
    {
      title: { id: 'nav.routes.logout', defaultMessage: 'Logout' },
      triggerAction: 'logout',
      menu: true,
      onlyShowIf: 'narrow && mobilePlatformRedirect', //special
    },
    {
      title: { id: 'nav.routes.terms', defaultMessage: 'Terms' },
      route: '/terms',
      component: Terms,
      needsAuth: true,
      menu: false, //under user menu
    },
  ],
  notAuthenticated: '/login',
  default: '/trade',
  admin: '/admin',
  basename: '', //the react router basename
};

export default routes;
