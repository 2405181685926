/** @format */

import DSM from '@mollybet/frontend-common/dist/lib/DSM';
import { toCamelCase } from '@mollybet/frontend-common/dist/lib/camelSnake';
import Whitelabel from '@mollybet/frontend-common/dist/lib/Whitelabel';
import Session from '@mollybet/frontend-common/dist/lib/Session';
import { fromJS } from 'immutable';
import config from '../config';
import Telemetry from '@mollybet/frontend-common/dist/lib/Telemetry';

let uiSpecs = {};
//narrowness of contents may dictate behaviours
if (window) {
  uiSpecs = {
    width: window.innerWidth,
    height: window.innerHeight,
    contentLayout: window.innerWidth <= config.narrowBreakpointContents ? 'narrow' : 'normal',
    navLayout: window.innerWidth <= config.narrowBreakpointNav ? 'narrow' : 'normal',
  };
}

let initialState = fromJS({
  uiSpecs,

  tickerMessages: [], //list of ticker messages
  isMessagesOverlayOpen: false, //is the ticker messages box open

  currentAnnouncement: '', //announcement that is currently visible

  isBetTypesOverlayOpen: false, //market bet type selection modal
  isFeedbackOverlayOpen: false, //is the feedback overlay open
  isFavouritesModalOpen: false,

  isTaggingMode: false, //is the event coloring mode enabled
  taggingColor: '', //what color is being used for tagging
  isAddingTipsterData: false, //is the tipster data parser open

  isPayoutCalcOpen: false, //is the payout calc modal open
  payoutCalcTargetOrder: null, //accum calculator target order

  //search state
  searchBarFocusState: 'unfocused', //the actual state of the search bar
  searchTarget: null,
  searchToggled: false,
  searchBarValue: '',

  parlayMode: false, //if we are in single placement mode or parlay mode
  fixedDraggablesOpen: false, //should fixed draggables be expanded

  hoveredOrderId: null, //the current order that is being hovered
  hoveredStatus: '', //the status that is hovered

  placementConfirmation: {
    placerId: null,
    betslipId: null,
    goDark: null,
    darkMin: null,
  },

  settings: {},
});

const functions = {
  //react to window resize
  windowResize: (state, _action) => {
    if (window) {
      return state.mergeDeepIn(['uiSpecs'], {
        width: window.innerWidth,
        height: window.innerHeight,
        contentLayout: window.innerWidth <= config.narrowBreakpointContents ? 'narrow' : 'normal',
        navLayout: window.innerWidth <= config.narrowBreakpointNav ? 'narrow' : 'normal',
      });
    } else {
      return state;
    }
  },

  ////// TICKER MESSAGES

  //set up a stream that updates ticker messages
  baseStreamConnect: (state, action) => {
    DSM.ensureOne(
      `/web/sessions/${Session.get('sessionId')}/ticker/`,
      {
        message: 'tickersResponse',
        method: 'GET',
        interval: config.timings.tickerMessages,
        ignoreErrors: true, //we care, but not that much...
      },
      action.data.actions,
      'statusStream'
    );

    return state;
  },

  //ticker messages response
  tickersResponse: (state, action) => {
    if (action.data.status === 'ok') {
      let toSet = action.data.data;
      return state.setIn(['tickerMessages'], fromJS(toSet));
    } else {
      return state;
    }
  },

  //show/close ticker messages overlay
  toggleMessagesOverlay: (state, _action) => {
    let messages = state.get('tickerMessages', null);
    if (messages) {
      messages.forEach((message) => {
        Session.set(['settings', 'general', 'seenTickers', message.get('id', '')], true);
        state = state.setIn(
          ['settings', 'general', 'seenTickers', '' + message.get('id', '')],
          true
        );
      });
    }
    return state.set('isMessagesOverlayOpen', !state.get('isMessagesOverlayOpen'));
  },

  ////// ANNOUNCEMENTS

  //check for announcements to show
  handlePrefs: (state, action) => {
    if (action.data.status === 'ok') {
      let userprefs = action.data.data;
      const isFirstLogin = !Object.keys(action.data.data).length;
      if (isFirstLogin) {
        userprefs.trade = userprefs.trade || {};
        userprefs.trade.expanded = {
          main: { markets: { favs: true, ir: true, today: true, early: true } },
          side: { markets: { favs: true, ir: true, today: true, early: true } },
        };
      }

      //set settings here as well
      //hopefully the other part of the reducer gets executed first
      state = state.set('settings', fromJS(userprefs));

      const isDemo =
        !!Whitelabel.demoPlatformLink &&
        (Whitelabel.demoPlatformLink.endsWith('/')
          ? Whitelabel.demoPlatformLink
          : `${Whitelabel.demoPlatformLink}/`) ===
          `//${
            window.location.host.endsWith('/') ? window.location.host : `${window.location.host}`
          }/`;
      // We dont want announcements in demo mode
      if (isDemo) return state;

      //show announcements (only if not in mobile mode)
      const showEssentialAnnoucementsOnly = state.getIn(
        ['settings', 'general', 'showEssentialAnnoucementsOnly'],
        false
      );

      for (let announcement in Whitelabel.announcements) {
        const annoucementIsEssential = true; // Whitelabel.announcements[announcement].isEssential;
        if (annoucementIsEssential || !showEssentialAnnoucementsOnly) {
          let d = new Date(state.getIn(['settings', 'announcements', announcement], 0));
          let td = new Date(Whitelabel.announcements[announcement].lastUpdated);
          let dateNow = new Date();
          if (td < dateNow && d < td) {
            state = state.set('currentAnnouncement', announcement);
            break;
          }
        }
      }
    }

    return state;
  },

  popUpSmartCreditAnnouncement: (state, _action) => {
    state = state.set('currentAnnouncement', 'smartCredit');
    return state;
  },

  //user acknowledges that he has seen announcement
  acceptAnnouncement: (state, _action) => {
    //mark the annoucement as accepted
    let announcement = state.get('currentAnnouncement');
    if (announcement) {
      let accepted = +new Date();
      Session.set(['settings', 'announcements', announcement], accepted);
      state = state.setIn(['settings', 'announcements', announcement], accepted);
      state = state.set('currentAnnouncement', '');
    }

    //if there is another annoucement - show it
    const showEssentialAnnoucementsOnly = state.getIn(
      ['settings', 'general', 'showEssentialAnnoucementsOnly'],
      false
    );
    for (let announcement in Whitelabel.announcements) {
      const annoucementIsEssential = Whitelabel.announcements[announcement].isEssential;
      if (annoucementIsEssential || !showEssentialAnnoucementsOnly) {
        let d = new Date(state.getIn(['settings', 'announcements', announcement], 0));
        let td = new Date(Whitelabel.announcements[announcement].lastUpdated);
        let dateNow = new Date();
        if (dateNow > td && d < td) {
          state = state.set('currentAnnouncement', announcement);
          break;
        }
      }
    }
    return state;
  },

  //user closes announcements
  skipAnnouncements: (state, _action) => {
    return state.set('currentAnnouncement', '');
  },

  ////// OVERLAYS AND MODALS

  //show/close bet type overlay
  toggleBetTypesOverlay: (state, _action) => {
    return state.set('isBetTypesOverlayOpen', !state.get('isBetTypesOverlayOpen'));
  },

  toggleFavouritesOverlay: (state, _action) => {
    return state.set('isFavouritesModalOpen', !state.get('isFavouritesModalOpen'));
  },

  //show/close feedback overlay
  toggleFeedbackOverlay: (state, _action) => {
    return state.set('isFeedbackOverlayOpen', !state.get('isFeedbackOverlayOpen'));
  },

  //show the payout calculator
  //optionally auto-populate with an order
  togglePayoutCalc: (state, action) => {
    Telemetry.recordGA('user_action', 'togglePayoutCalc', 'togglePayoutCalc');
    if (action.data && action.data.orderId) {
      state = state.set('payoutCalcTargetOrder', action.data.orderId);
    }
    let setTo = !state.get('isPayoutCalcOpen', false);
    if (!setTo) {
      state = state.set('payoutCalcTargetOrder', null);
    }
    return state.set('isPayoutCalcOpen', setTo);
  },

  ////// EVENT TAGGING

  toggleEventTaggingMode: (state, _action) => {
    Telemetry.recordGA('user_action', 'taggingAction', 'toggleEventTaggingMode');
    let current = state.get('isTaggingMode', false);
    return state.set('isTaggingMode', !current);
  },

  changeEventTaggingColor: (state, action) => {
    Telemetry.recordGA('user_action', 'taggingAction', 'changeEventTaggingColor');
    return state.set('taggingColor', action.data.color);
  },

  changeEventTagColor: (state, action) => {
    Telemetry.recordGA('user_action', 'taggingAction', 'changeEventTagColor');
    let { eventId, info } = action.data;
    let color = state.get('taggingColor');

    if (color) {
      Session.set(['settings', 'trade', 'tags', eventId], {
        color,
        info,
      });

      state = state.setIn(
        ['settings', 'trade', 'tags', eventId],
        fromJS({
          color,
          info,
        })
      );
    } else {
      Session.clear(['settings', 'trade', 'tags', eventId]);
      state = state.removeIn(['settings', 'trade', 'tags', eventId]);
    }

    return state;
  },

  ////// TRADE SEARCH

  //focus search bar
  focusSearchBar: (state, _action) => {
    return state.setIn(['searchBarFocusState'], 'focused');
  },

  //unfocus search bar
  unfocusSearchBar: (state, _action) => {
    return state.setIn(['searchBarFocusState'], 'unfocused');
  },

  setSearchBarValue: (state, action) => {
    return state.set('searchBarValue', action.data);
  },

  //open search bar (important for mobile interfaces)
  openSearch: (state, _action) => {
    return state.setIn(['searchToggled'], true);
  },

  //close search bar (important for mobile interfaces)
  closeSearch: (state, _action) => {
    return state.setIn(['searchToggled'], false);
  },

  //highlight something because it's hovered in the search
  setSearchTarget: (state, action) => {
    return state.set('searchTarget', action.data.target);
  },

  ////// PARLAY MODE

  //switch between single bet and parlay mode
  togglePlacementMode: (state, _action) => {
    let parlayMode = state.get('parlayMode', false);
    return state.set('parlayMode', !parlayMode);
  },

  ////// ASIAN MODE SIDE DRAWER

  //close fixed draggables on side on asian view
  closeFixedDraggables: (state, _action) => {
    return state.set('fixedDraggablesOpen', false);
  },

  //open fixed draggable on side of asian view
  openFixedDraggables: (state, _action) => {
    return state.set('fixedDraggablesOpen', true);
  },

  ////// BETBAR ORDER SUMMARY HOVER

  //need the order id and the 'chunk' of status hovered (done, unplaced, etc.)
  betslipOrderShowHover: (state, action) => {
    state = state.set('hoveredOrderId', action.data.orderId);
    return state.set('hoveredStatus', action.data.orderStatus);
  },

  //reset overlay state
  betslipOrderHideHover: (state, _action) => {
    state = state.set('hoveredOrderId', null);
    return state.set('hoveredStatus', '');
  },

  ////// PLACER CONFIRMATION POPUP

  placerPopConfirm: (state, action) => {
    let placerId = action.data.placerId;
    let betslipId = action.data.betslipId;
    let parlayId = action.data.parlayId;
    state = state.setIn(['placementConfirmation', 'parlayId'], parlayId);
    state = state.setIn(['placementConfirmation', 'placerId'], placerId);
    state = state.setIn(['placementConfirmation', 'goDark'], action.data.goDark);
    state = state.setIn(['placementConfirmation', 'darkMin'], action.data.darkMin);
    return state.setIn(['placementConfirmation', 'betslipId'], betslipId);
  },

  placerAcceptConfirm: (state, _action) => {
    //do something else?
    state = state.removeIn(['placementConfirmation', 'parlayId']);
    state = state.removeIn(['placementConfirmation', 'placerId']);
    return state.removeIn(['placementConfirmation', 'betslipId']);
  },

  placerRejectConfirm: (state, _action) => {
    state = state.removeIn(['placementConfirmation', 'parlayId']);
    state = state.removeIn(['placementConfirmation', 'placerId']);
    return state.removeIn(['placementConfirmation', 'betslipId']);
  },

  // CASHOUT placement check

  cashoutPlacerOpen: (state, action) => {
    const { positionId, eventId, sport } = action.data;
    state = state.setIn(['placementConfirmation', 'showCashoutConfirmation'], true);
    state = state.setIn(['placementConfirmation', 'cashout', 'positionId'], positionId);
    state = state.setIn(['placementConfirmation', 'cashout', 'eventId'], eventId);
    state = state.setIn(['placementConfirmation', 'cashout', 'sport'], sport);
    return state;
  },

  cashoutPlacerClose: (state, _action) => {
    state = state.setIn(['placementConfirmation', 'showCashoutConfirmation'], false);
    state = state.removeIn(['placementConfirmation', 'cashout', 'positionId']);
    state = state.removeIn(['placementConfirmation', 'cashout', 'eventId']);
    state = state.removeIn(['placementConfirmation', 'cashout', 'sport']);
    return state;
  },

  ////// BETBAR STATE

  //expand or contract the betbar
  toggleBetbarExpanded: (state, _action) => {
    let isExpanded = state.getIn(['settings', 'trade', 'betbar', 'expanded'], false);
    Session.set(['settings', 'trade', 'betbar', 'expanded'], !isExpanded);
    state = state.setIn(['settings', 'trade', 'betbar', 'expanded'], !isExpanded);
    return state;
  },

  //expand or contract the betbar
  openBetbar: (state, _action) => {
    Session.set(['settings', 'trade', 'betbar', 'expanded'], true);
    state = state.setIn(['settings', 'trade', 'betbar', 'expanded'], true);
    return state;
  },

  //should show closed orders in the betbar
  toggleBetbarShowClosed: (state, _action) => {
    let showClosed = state.getIn(['settings', 'trade', 'betbar', 'showClosed'], false);
    Session.set(['settings', 'trade', 'betbar', 'showClosed'], !showClosed);
    state = state.setIn(['settings', 'trade', 'betbar', 'showClosed'], !showClosed);
    return state;
  },

  //should show only current user's orders
  toggleBetbarShowOnlyMine: (state, _action) => {
    let showOnlyMine = state.getIn(['settings', 'trade', 'betbar', 'showOnlyMine'], false);
    Session.set(['settings', 'trade', 'betbar', 'showOnlyMine'], !showOnlyMine);
    state = state.setIn(['settings', 'trade', 'betbar', 'showOnlyMine'], !showOnlyMine);
    return state;
  },

  ////// TRADE INTERFACE STATE

  //change currently focused sport
  changeSport: (state, action) => {
    Session.set(['settings', 'trade', 'sport'], action.data.sport);
    return state.setIn(['settings', 'trade', 'sport'], action.data.sport);
  },

  //this toggles the trade page side
  //this could live in the trade reducer, but it causes more complexity
  toggleTradeSide: (state, _action) => {
    let isOpen = state.getIn(['settings', 'trade', 'side', 'open'], false);
    Telemetry.recordGA('user_action', 'toggleTradeSide', `${isOpen ? 'closing' : 'opening'}`);
    state = state.setIn(['settings', 'trade', 'side', 'open'], !isOpen);
    Session.set(['settings', 'trade', 'side', 'open'], !isOpen);
    return state;
  },

  //toggle a main trade market (the one with events in it)
  marketMainToggle: (state, action) => {
    let flipped = !state.getIn(
      ['settings', 'trade', 'expanded', 'main', 'markets', action.data.marketId],
      false
    );
    Session.set(
      ['settings', 'trade', 'expanded', 'main', 'markets', action.data.marketId],
      flipped
    );
    state = state.setIn(
      ['settings', 'trade', 'expanded', 'main', 'markets', action.data.marketId],
      flipped
    );
    return state;
  },

  //toggle a side trade market (the one in the sidebar with just competitions)
  marketSideToggle: (state, action) => {
    let flipped = !state.getIn(
      ['settings', 'trade', 'expanded', 'side', 'markets', action.data.marketId],
      false
    );
    Session.set(
      ['settings', 'trade', 'expanded', 'side', 'markets', action.data.marketId],
      flipped
    );
    state = state.setIn(
      ['settings', 'trade', 'expanded', 'side', 'markets', action.data.marketId],
      flipped
    );
    return state;
  },

  //set the state of a main trade market (the one with events in it)
  marketMainSet: (state, action) => {
    Session.set(
      ['settings', 'trade', 'expanded', 'main', 'markets', action.data.marketId],
      !!action.data.value
    );
    state = state.setIn(
      ['settings', 'trade', 'expanded', 'main', 'markets', action.data.marketId],
      !!action.data.value
    );
    return state;
  },

  //set state of a side trade market (the one in the sidebar with just competitions)
  marketSideSet: (state, action) => {
    Session.set(
      ['settings', 'trade', 'expanded', 'side', 'markets', action.data.marketId],
      !!action.data.value
    );
    state = state.setIn(
      ['settings', 'trade', 'expanded', 'side', 'markets', action.data.marketId],
      !!action.data.value
    );
    return state;
  },

  //close the early market (used to reduced load on lower spec platforms)
  closeTradeEarlyToday: (state, action) => {
    //try to save mobile users from themselves
    if (action.data.navLayout === 'narrow') {
      state = state.setIn(['settings', 'trade', 'expanded', 'main', 'markets', 'early'], false);
      state = state.setIn(['settings', 'trade', 'expanded', 'side', 'markets', 'early'], false);
    }
    if (action.data.contentLayout === 'narrow') {
      state = state.setIn(['settings', 'trade', 'expanded', 'main', 'markets', 'today'], false);
      state = state.setIn(['settings', 'trade', 'expanded', 'side', 'markets', 'today'], false);
    }
    return state;
  },

  //sets what the user wants to sort multirunners prices based on
  sortMultirunners: (state, action) => {
    Telemetry.recordGA('user_action', 'sortMultirunners', action.data);
    state = state.setIn(['settings', 'trade', 'multirunnersSort'], action.data);
    Session.set(['settings', 'trade', 'multirunnersSort'], action.data);
    return state;
  },

  //sets what the user wants to sort multirunners prices based on
  toggleMultirunnerEventOpen: (state, action) => {
    const isOpen = state.getIn(
      ['settings', 'trade', 'multirunnerEventOpen', action.data.eventId],
      true
    );
    Telemetry.recordGA('user_action', 'toggleMultirunnerLines', action.data);
    state = state.setIn(
      ['settings', 'trade', 'multirunnerEventOpen', action.data.eventId],
      !isOpen
    );
    Session.set(['settings', 'trade', 'multirunnerEventOpen', action.data.eventId], !isOpen);
    return state;
  },

  ////// SETTINGS

  //this is a generic way of applying interface and settings changes
  applySettings: (state, action) => {
    //basically overwrite all the settings
    if (action.data) {
      state = state.mergeDeepIn(['settings'], action.data);
    }

    //wonderful exceptions
    if (action.data && action.data['trade']) {
      if (action.data['trade']['pricesBookies']) {
        state = state.setIn(
          ['settings', 'trade', 'pricesBookies'],
          fromJS(action.data['trade']['pricesBookies'])
        );
      }
      if (action.data['trade']['disabledBookies']) {
        state = state.setIn(
          ['settings', 'trade', 'disabledBookies'],
          fromJS(action.data['trade']['disabledBookies'])
        );
      }
    }

    if (action.data && action.data['history']) {
      if (action.data['history']['bets'] && action.data['history']['bets']['views']) {
        state = state.setIn(
          ['settings', 'history', 'bets', 'views'],
          fromJS(action.data['history']['bets']['views'])
        );
      }
    }

    //base already updates Session

    return state;
  },

  ////// DUMMY

  //dummy action so that GA records it
  useMobileInterface: (state, _action) => {
    return state;
  },

  //dummy action so that GA records it
  useDesktopInterface: (state, _action) => {
    return state;
  },
};

export default function reducer(state = initialState, action) {
  let _action = toCamelCase(action.type);
  return functions[_action] ? functions[_action](state, action) : state;
}

export let actions = {};
for (let ct in functions) {
  actions[ct] = (data, noGA, noLog) => ({ type: ct, data, noGA, noLog });
}
