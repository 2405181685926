/** @format */

import config from '../../config';

import { actions } from '../../reducers/app';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

import styled, { withTheme } from 'styled-components';
import MenuOptions from './MenuOptions';
import CreditInformation from './CreditInformation';

import { SettingsContext } from '../../components/shared/SettingsContext';
import { formatAmount } from '@mollybet/frontend-common/dist/lib/formatters';

import { Paper } from '../../components/interface';

import ComponentErrorBoundary from '../error-boundary/ComponentErrorBoundary';

const UserNavDisplay = styled.div`
  max-width: 20em;
  padding: 0em 4em 0 2em;
  height: ${config.nav.styles.wide.height};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  box-sizing: border-box;

  background: ${({ sudoer }) => (sudoer ? '#FFBF00' : 'inherit')};
  color: ${({ sudoer }) => (sudoer ? 'black' : 'inherit')};

  @media only screen and (max-width: ${config.narrowBreakpointNav}px) {
    height: ${config.nav.styles.narrow.height};
    padding: 0em 1em 0em 0.5em;
  }
`;

const Divider = styled.div`
  width: 100%;
  padding-top: 3px;
  height: 3px;
  border-bottom: solid 1px ${(props) => props.theme.separator};
`;


const MoneyHolderComp = styled.div`
  z-index: 2;
  height: ${config.nav.styles.wide.height};
  user-select: none;
  -webkit-user-select: none;

  @media only screen and (max-width: ${config.narrowBreakpointNav}px) {
    height: ${config.nav.styles.narrow.height};
  }
`;

const MoneyHolderDropdown = styled(Paper)`
  position: absolute;
  top: ${config.nav.styles.wide.height};
  right: 0;
  padding: 0.5em;
  width: 20em;

  .terms-and-logout {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
  }

  @media only screen and (max-width: ${config.narrowBreakpointNav}px) {
    max-height: calc(100vh - 56px);
    top: ${config.nav.styles.narrow.height};
    overflow-y: auto;
  }
`;

const BalanceFlex = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
`

const Balance = styled.span`
  margin-left: 5px;
  padding: 2px;
`


class MoneyHolder extends React.PureComponent {
  static contextType = SettingsContext;

  state = {
    creditExpanded: false,
    trend: '',
  };
  _autoCooldown = null;


  componentDidUpdate(prevProps, _prevState) {
    if (prevProps.credit && this.props.credit !== prevProps.credit) {
      let trend = '';
      if (this.props.credit < prevProps.credit) {
        trend = 'decrease';
      } else if (this.props.credit > prevProps.credit) {
        trend = 'increase';
      }
      this.setState({ trend });
    }

    if (this.state.trend) {
      if (this._autoCooldown) {
        clearTimeout(this._autoCooldown);
        this._autoCooldown = null;
      }
      this._autoCooldown = setTimeout(
        () => this.setState({ trend: '' }),
        config.timings.creditUpdate
      );
    }
  }

  componentWillUnmount = () => {
    if (this._autoCooldown) {
      clearTimeout(this._autoCooldown);
    }
  };

  toggleCredit = () => {
    this.props.actions.closeSearch();
    this.setState({ creditExpanded: !this.state.creditExpanded });
  };

  createDeposit = () => {
    this.props.actions.closeSearch();
    this.props.actions.openDepositWithdrawForm({
      direction: 'deposit',
    });
  };

  requestToken = (url) => () => {
    this.props.actions.requestToken({
      actions: this.props.actions,
      url,
      lang: this.context.language,
    });
  };

  toggleFeedbackOverlay = () => {
    this.props.actions.toggleFeedbackOverlay();
  };

  toggleBetTypesOverlay = () => {
    this.props.actions.toggleBetTypesOverlay();
  };

  render() {
    const smartCredit =
      this.context.featureSmartCredit &&
      this.props.smartCredit != null &&
      !isNaN(this.props.smartCredit)
        ? this.props.smartCredit
        : 0;
    const showSmartCredit = this.props.smartCredit !== null && this.context.featureSmartCredit;
    return (
      <MoneyHolderComp
        onClick={this.toggleCredit}
        onMouseEnter={this.props.navLayout === 'narrow' ? null : this.toggleCredit}
        onMouseLeave={this.props.navLayout === 'narrow' ? null : this.toggleCredit}
      >
      
        <ComponentErrorBoundary>
          <UserNavDisplay sudoer={this.props.sudoer}>
            {!this.props.sudoer && this.props.hideUsername ? (
              !this.props.sudoer && this.props.hideCredit ? (
                <FormattedMessage id="menu.user" defaultMessage="User" />
              ) : null
            ) : (
              <span>{this.props.username}</span>
            )}
            &nbsp;
            {this.props.hideCredit || this.props.navLayout === 'narrow' ? null : (
              <BalanceFlex>
                <Balance smart={smartCredit}>
                  {this.props.creditLimit !== null ? (
                    formatAmount(
                      this.props.credit + smartCredit,
                      this.props.ccyCode,
                      this.props.displayCcy,
                      this.props.xrates,
                      undefined,
                      undefined,
                      this.props.credit > (this.props.hideUsername ? 1000000 : 1000)
                    )
                  ) : this.props.totalBookieBalance != null ? (
                    formatAmount(
                      this.props.totalBookieBalance + smartCredit,
                      this.props.ccyCode,
                      this.props.displayCcy,
                      this.props.xrates,
                      undefined,
                      undefined,
                      this.props.totalBookieBalance > (this.props.hideUsername ? 1000000 : 1000)
                    )
                  ) : (
                    <FormattedMessage
                      id="menu.money.creditLimitUnlimited"
                      defaultMessage="Unlimited"
                    />
                  )}
                </Balance>
              </BalanceFlex>
            )}
          </UserNavDisplay>
        </ComponentErrorBoundary>
        {this.state.creditExpanded && (
          <MoneyHolderDropdown elevation={4}>
            <ComponentErrorBoundary>
              <CreditInformation
                showSmartCredit={showSmartCredit}
                props={this.props}
                createDeposit={this.createDeposit}
              />
            </ComponentErrorBoundary>
            <Divider />
            <ComponentErrorBoundary>
              <MenuOptions
                props={this.props}
                context={this.context}
                toggleBetTypesOverlay={this.toggleBetTypesOverlay}
                toggleFeedbackOverlay={this.toggleFeedbackOverlay}
                requestToken={this.requestToken}
              />
            </ComponentErrorBoundary>
          </MoneyHolderDropdown>
        )}
      </MoneyHolderComp>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

const mapStateToProps = (state, _ownProps) => {
  const smartCredit = state.getIn(['base', 'profile', 'smartCredit'], null);
  return {
    ccyCode: state.getIn(['base', 'profile', 'ccyCode'], '?').toLowerCase(),
    username: state.getIn(['base', 'profile', 'username'], '?'),
    name: state.getIn(['base', 'profile', 'name'], '?'),

    creditUrl: state.getIn(['base', 'profile', 'creditUrl'], ''),
    credit: state.getIn(['base', 'profile', 'credit'], 0),
    creditLimit: state.getIn(['base', 'profile', 'creditLimit'], null),
    totalBookieBalance: state.getIn(['base', 'profile', 'totalBookieBalance'], null),
    groupId: state.getIn(['base', 'profile', 'groupId'], '?'),
    userId: state.getIn(['base', 'profile', 'id'], '?'),
    sudoer: state.getIn(['base', 'profile', 'sudoer'], null),

    balance: state.getIn(['base', 'profile', 'balance'], 0),
    yesterdayPl: state.getIn(['base', 'profile', 'yesterdayPl'], 0),
    todayPl: state.getIn(['base', 'profile', 'todayPl'], 0),
    openStakes: state.getIn(['base', 'profile', 'openStakes'], 0),
    smartCredit: smartCredit != null ? smartCredit : null,
    profile: state.getIn(['base', 'profile'], null),
    hideCredit: state.getIn(['base', 'settings', 'general', 'hideCredit'], false),
    hideUsername: state.getIn(['base', 'settings', 'general', 'hideUsername'], false),

    xrates: state.getIn(['base', 'xrates'], null),
    displayCcy: state
      .getIn(
        ['base', 'settings', 'general', 'displayCcy'],
        state.getIn(['base', 'profile', 'ccyCode'], '?')
      )
      .toLowerCase(),
    navLayout: state.getIn(['ui', 'uiSpecs', 'navLayout'], 'normal'),
    contentLayout: state.getIn(['ui', 'uiSpecs', 'contentLayout'], 'normal'),
    whitelabelUser: state.getIn(['base', 'switches', 'whitelabelUser'], false),
    externalURLsToken: state.getIn(['base', 'profile', 'externalURLsToken'], ''),
    externalURLsLang: state.getIn(['base', 'profile', 'externalURLsLang'], ''),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withTheme(MoneyHolder)));
